import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

Vue.mixin({
    computed: {
        ...mapGetters('blocks', {
            _findBlocks: 'find',
        }),
        ...mapGetters('chains', {
            chain: 'current',
        }),
        ...mapState('chains', {
            chainId: 'currentId', // FIXME: myChainId
        }),
        ...mapGetters('leaves', {
            leaf: 'current',
            _findLeaves: 'find',
        }),
        ...mapState('leaves', {
            leafId: 'currentId',
        }),

        GAZILLION_DEFAULT_CHAIN() {
            return this.$env.GAZILLION_DEFAULT_CHAIN.toLowerCase();
        },

        // CHAIN
        chainBlocks() {
            return (
                this._findBlocks({ query: this.chainBlocksQuery }).data || []
            );
        },
        chainBlocksQuery() {
            return this.chainId
                ? {
                    chain: this.chainId,
                    id: { $lte: this.$env.CHAIN_SIZE },
                    $sort: { id: 1 },
                }
                : { $limit: 0 };
        },
        chainLeaves() {
            return (
                this._findLeaves({ query: this.chainLeavesQuery }).data || []
            );
        },
        chainLeavesQuery() {
            return this.chainId
                ? {
                    chain: this.chainId,
                    id: { $lte: this.$env.LEAVES_MAX_ELIGIBLE },
                    $sort: { id: 1 },
                    // FIXME:       $select: ['name', 'validation'],
                }
                : { $limit: 0 };
        },
        chainValidationConsensus() {
            return (
                this.chainValidationsPassed.length /
                    this.chainValidations.length >
                0.5
            );
        },
        chainValidationsFailed() {
            return (
                this._findLeaves({
                    query: {
                        chain: this.chainId,
                        status: 'failed', // FIXME: constants from '@/store/'
                    },
                }).data || []
            );
        },
        chainValidationsPassed() {
            return (
                this._findLeaves({
                    query: {
                        chain: this.chainId,
                        status: 'validated', // FIXME: constants from '@/store/'
                    },
                }).data || []
            );
        },
        chainValidationsPending() {
            return this.chainId
                ? this._findLeaves({
                    query: {
                        chain: this.chainId,
                        status: 'validating', // FIXME: constants from '@/store/'
                    },
                }).data
                : null || [];
        },
        chainValidations() {
            return (
                this._findLeaves({
                    query: {
                        chain: this.chainId,
                        validation: { $not: null },
                    },
                }).data || []
            );
        },
        chainHasBeenStarted() {
            return this.chain ? this.chain.leaves > 0 : false;
        },
        chainIsLocked() {
            return this.chain ? this.chain.locked : false;
        },
        chainIsPresentationChain() {
            return this.chainId
                ? this.chainId == this.GAZILLION_DEFAULT_CHAIN
                : false;
        },

        // USER
        userIsPresenter() {
            return this.$auth.loggedIn;
        },
        userHasBuiltLeaf() {
            return this.leaf && this.leaf.block;
        },
        userIsSuperuser() {
            return this.chainIsPresentationChain ? this.userIsPresenter : true;
        },
        userMayCreateOrResetChain() {
            return this.userIsPresenter;
        },
        userMayGenerateLeaves() {
            return this.userIsSuperuser;
        },
        userCanGenerateLeaves() {
            return !this.chainIsLocked;
        },
        userMayPreviewValidation() {
            return this.userIsSuperuser;
        },
        // FIXME: CAN (disabled) v. MAY (visible)
        userMayRequestValidation() {
            return this.chainIsPresentationChain
                ? this.userIsPresenter
                : this.chainValidationsPending.length == 0;
        },
        userMaySendReceipts() {
            return this.userIsPresenter;
        },
        userCanSendReceipts() {
            return !this.chainIsLocked && this.chainBlocks.length;
        },
        userMaySubmitLeaf() {
            return (
                !this.chainIsLocked &&
                (this.userIsSuperuser ||
                    (this.chainHasBeenStarted && !this.leaf))
            );
        },

        // SYSTEM
        systemShouldStartTourAutomatically() {
            return !this.chainIsPresentationChain;
        },
    },
});
