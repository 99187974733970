<template>
    <span v-if="authEnabled">
        <v-btn
            v-if="$auth.loggedIn"
            @click="$auth.logout()"
            text
            title="Log out"
            small
        >
            <v-icon small>mdi-logout</v-icon>
        </v-btn>
        <v-btn
            v-else
            @click="$auth.loginWith('auth0')"
            text
            title="Log in (administrators/presenters only)"
            small
        >
            <v-icon small>mdi-key</v-icon>
        </v-btn>
    </span>
</template>

<script>
export default {
    name: 'AuthActions',
    data() {
        return {
            authEnabled: this.$env.GAZILLION_FEAT_AUTHENTICATION,
        };
    },
};
</script>
