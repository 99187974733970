<template>
    <v-app>
        <Manager />
        <v-content>
            <v-container fill-height>
                <nuxt />
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
import Manager from '@/components/Manager.vue';

export default {
    components: {
        Manager,
    },
};
</script>
