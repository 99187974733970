<template>
    <div>
        <v-system-bar app>
            <template v-if="chain">
                <v-chip x-small>
                    <v-avatar>
                        <v-icon v-if="chainIsLocked">mdi-link-lock</v-icon>
                        <v-icon v-else>mdi-link</v-icon>
                    </v-avatar>
                    <kbd>{{ chain.publicKey }}</kbd>
                </v-chip>
                <v-chip x-small>
                    {{ chain.blocks }}
                    {{ chain.blocks == 1 ? 'block' : 'blocks' }}
                    <template v-if="chain.blocks > $env.BLOCKS_MAX">
                        &nbsp;({{ $env.BLOCKS_MAX }} shown)
                    </template>
                </v-chip>
            </template>
            <v-spacer />
            <v-chip
                @click="$router.go()"
                color="error"
                v-if="hasDisconnected"
                title="Click to reload"
                x-small
            >
                Offline
                <v-icon right x-small color="white">mdi-refresh</v-icon>
            </v-chip>
            <AuthActions />
            <v-btn
                small
                text
                title="Create or open another blockchain"
                :href="$router.resolve({ name: 'index' }).href"
            >
                <v-icon small>mdi-home</v-icon>
            </v-btn>
            <v-btn text @click="doClose" v-if="isNewWindowOrTab" title="Close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-system-bar>
        <v-app-bar app>
            <v-toolbar-title>Gazillion Blockchain&nbsp;</v-toolbar-title>
            <v-btn
                icon
                @click="$root.$emit('openSubmit')"
                color="primary"
                :disabled="!userMaySubmitLeaf"
                outlined
                id="button-open-submit"
                v-if="!!chain"
                title="Prepare an entry for the blockchain"
            >
                <v-icon>mdi-play</v-icon>
            </v-btn>
            <v-btn
                icon
                id="button-open-generator"
                v-if="!!chain && userMayGenerateLeaves"
                @click="$root.$emit('openGenerator')"
                :disabled="!userCanGenerateLeaves"
                title="Auto-generate entries"
            >
                <v-icon>mdi-playlist-plus</v-icon>
            </v-btn>
            <v-btn
                icon
                v-if="!!chain && userMaySendReceipts && sendReceiptsEnabled"
                @click="doSendReceipts()"
                :disabled="!userCanSendReceipts"
                title="Send receipts"
            >
                <v-icon>mdi-file-send</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
                v-if="chain && !this.chainIsPresentationChain"
                icon
                :disabled="tourIsRunning"
                id="help-button"
                @click="$root.$emit('startTour')"
                title="Help"
            >
                <v-icon>mdi-help-circle</v-icon>
            </v-btn>
        </v-app-bar>
        <JoinMessage v-if="joinMessageEnabled" />
        <Tour v-if="!!chain" v-bind:running.sync="tourIsRunning" />
        <v-snackbar
            absolute
            color="error"
            :timeout="0"
            v-model="chainShowError"
        >
            We couldn't find that chain for you.
            <template v-if="chainErrorIsTimeout">
                This appears to have been a network problem, so you may want to
                reload the page and try again.
                <v-btn text @click="$router.go(0)">
                    Reload
                </v-btn>
            </template>
            <v-btn v-else text :href="$router.resolve({ name: 'index' }).href">
                Home
            </v-btn>
        </v-snackbar>
        <v-snackbar
            v-if="chain"
            absolute
            color="success"
            v-model="sendChainReceiptsShowSuccess"
        >
            {{ chain.leaves }} receipts queued for distribution.
        </v-snackbar>
    </div>
</template>

<script>
import { Timeout } from '@feathersjs/errors';
import { mapActions, mapState } from 'vuex';

import AuthActions from '@/components/AuthActions';
import JoinMessage from '@/components/JoinMessage';
import Tour from '@/components/Tour';

export default {
    name: 'Manager',
    components: {
        AuthActions,
        JoinMessage,
        Tour,
    },
    data() {
        return {
            hasDisconnected: false,
            isNewWindowOrTab: !!window.opener,

            joinMessageEnabled: this.$env.GAZILLION_FEAT_JOIN_MESSAGE,
            sendReceiptsEnabled: this.$env.GAZILLION_FEAT_SEND_RECEIPTS,

            chainShowError: false,
            sendChainReceiptsShowSuccess: false,

            tourIsRunning: false,
        };
    },

    computed: {
        ...mapState('chains', {
            chainCreateError: 'errorOnCreate',
            chainGetError: 'errorOnGet',
        }),
        chainError() {
            return this.chainCreateError || this.chainGetError;
        },
        chainErrorIsTimeout() {
            return this.chainError && this.chainError.name == 'Timeout';
        },
        isDisconnected() {
            return this.$socket.disconnected;
        },
    },

    methods: {
        ...mapActions('send-chain-receipts', {
            sendChainReceipts: 'create',
        }),
        doClose() {
            window.close();
        },
        async doSendReceipts() {
            await this.sendChainReceipts({ publicKey: this.chainId });
            this.sendChainReceiptsShowSuccess = true;
        },
    },

    watch: {
        chainError(chainError) {
            this.chainShowError = chainError;
        },
        isDisconnected(isDisconnected) {
            if (isDisconnected) this.hasDisconnected = true;
        },
    },
};
</script>
