<template>
    <v-sheet>
        <v-dialog persistent v-model="introductionShow" width="70%">
            <v-card>
                <v-card-title
                    >Welcome to the Gazillion blockchain tour.</v-card-title
                >
                <v-card-text>
                    Please follow the prompts to submit a blockchain entry.
                    Next, watch as additional entries are submitted to build a
                    block. Finally, request validation of your entry, and then
                    test your validation by tampering with the information you
                    submitted to this immutable ledger. You may end the tour at
                    any time (by selecting the
                    <v-icon>mdi-stop-circle-outline</v-icon> icon) to explore
                    the blockchain on your own.
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        @click="startTour()"
                        id="button-start-tour"
                    >
                        Get Started
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-tour name="tour" :callbacks="callbacks" :steps="steps">
            <template slot-scope="tour">
                <transition name="fade">
                    <v-step
                        v-if="tour.steps[tour.currentStep]"
                        :step="tour.steps[tour.currentStep]"
                        :key="tour.currentStep"
                        :previous-step="tour.previousStep"
                        :next-step="tour.nextStep"
                        :stop="tour.stop"
                        :is-first="tour.isFirst"
                        :is-last="tour.isLast"
                        :labels="tour.labels"
                    >
                        <div slot="actions">
                            <v-btn
                                dark
                                icon
                                outlined
                                v-if="tour.isLast"
                                @click.prevent="tour.stop"
                            >
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                            <v-btn
                                dark
                                icon
                                outlined
                                v-else
                                @click.prevent="tour.stop"
                            >
                                <v-icon>mdi-stop</v-icon>
                            </v-btn>
                            <v-btn
                                dark
                                icon
                                outlined
                                v-if="tour.steps[tour.currentStep].allowNext"
                                @click.prevent="tour.nextStep"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                    </v-step>
                </transition>
            </template>
        </v-tour>
        <v-dialog v-model="conclusionShow" width="70%">
            <v-card>
                <v-card-title>Thanks!</v-card-title>
                <v-card-text>
                    Thank you for participating in building this blockchain. You
                    should now have an initial understanding of the basic
                    functioning of blockchains. You can continue exploring on
                    your own, or you can select the
                    <v-icon>mdi-help-circle</v-icon> icon to restart the guided
                    tour.
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="conclusionShow = false" color="primary">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-sheet>
</template>

<script>
export default {
    name: 'Tour',
    props: ['running'],
    data() {
        return {
            wasForceStopped: false,
            conclusionShow: false,
            introductionShow: false,

            callbacks: {
                onStop: this.onStop,
            },
            steps: [
                {
                    target: '#button-open-submit',
                    content: `To start building your blockchain, select the ▶ icon above to submit an entry.`,
                },
                {
                    target: '#button-submit',
                    content: `Once you've filled out the form, select here to submit your entry to the blockchain.`,
                },
                {
                    target: '#button-open-generator',
                    content: `You'll need at least four entries to build a blockchain.  Select here to generate some.`,
                },
                {
                    target: '#button-generate',
                    content: `Adjust the slider to set how many entries you want to generate, then select here to add them to the blockchain.`,
                },
                {
                    target: '.graph',
                    content: `Now you have a blockchain!  The records you've generated have been linked together and distributed to all participants.  Everyone holds an independent copy they can use to verify the entire blockchain.`,
                    allowNext: true,
                },
                {
                    target: '#tab-receipt',
                    content: `Now that you have a blockchain, ask other participants to validate your receipt.`,
                    params: {
                        placement: 'top',
                    },
                },
                {
                    target: '#chainStatus',
                    content: `The participants highlighted in yellow will be asked to validate your request.  If they are computer-generated participants, they’ll do so automatically.`,
                    allowNext: true,
                },
                {
                    target: '#chainStatus',
                    content: `All done!  Your receipt was sent to the participants selected at random and shown to them for verification.  This can happen right away, as in this example, to prove that you submitted what you intended to submit; this keeps the central record honest.  Or this can happen at any time down the road, to prove that nothing has been tampered with.`,
                    allowNext: true,
                },
                {
                    target: '#tab-receipt',
                    content: `Now try tampering with (editing) your receipt.  Then request validation again.`,
                    params: {
                        placement: 'top',
                    },
                },
                {
                    target: '#chainStatus',
                    content: `The participants highlighted in yellow will be asked to validate your request.  If they are computer-generated participants, they’ll do so automatically.`,
                    allowNext: true,
                },
                {
                    target: '#chainStatus',
                    content: `All done.`,
                },
            ],
        };
    },

    methods: {
        startTour() {
            console.info('tour: start');
            this.introductionShow = false;
            this.$tours['tour'].start();
            this.$emit('update:running', true);
        },
        onStop() {
            // per pulsardev/vue-tour#20
            if (!this.$tours['tour'].isRunning) return;
            console.info('tour: stop');
            this.conclusionShow = !this.wasForceStopped;
            this.$emit('update:running', false);
        },
    },

    mounted() {
        this.introductionShow = this.systemShouldStartTourAutomatically;
        this.$root.$on('startTour', () => {
            this.introductionShow = true;
        });
        this.$root.$on('stopTour', () => {
            this.wasForceStopped = true;
            this.$tours['tour'].stop();
        });
        this.$root.$on('advanceTour', () => {
            if (this.$tours['tour'].isRunning) this.$tours['tour'].nextStep();
        });
    },

    watch: {
        wasForceStopped(wasForceStopped) {
            if (wasForceStopped) {
                this.introductionShow = false;
                this.conclusionShow = false;
            }
        },
    },
};
</script>

<style scoped>
>>> .v-step {
    z-index: 255;
}
</style>
