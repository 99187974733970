import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import io from 'socket.io-client';
import socketio from '@feathersjs/socketio-client';

import Vue from 'vue';
import VueSocketIOExt from 'vue-socket.io-extended';

const socket = io({
    transports: ['websocket'],
    upgrade: false,
});

// Clear outbound buffer after (e.g.) connection timeout per
// feathersjs/feathers#1532.
socket.on('reconnect', function () {
    socket.sendBuffer = [];
});

Vue.use(VueSocketIOExt, socket);

export default feathers()
    .configure(socketio(socket))
    .configure(auth());
