<template>
    <v-snackbar :timeout="2000" v-if="!!latestLeaf" v-model="latestLeafShow">
        {{ latestLeaf.name }} (#{{ latestLeaf.id }}) just joined the blockchain
    </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'JoinMessage',
    data() {
        return {
            latestLeafQuery: {
                chain: this.chainId,
                $limit: 1,
                $sort: { id: -1 },
            },
            latestLeafShow: false,
        };
    },

    computed: {
        ...mapGetters('leaves', {
            _findLeaves: 'find',
        }),
        latestLeaf() {
            const latest = this._findLeaves({
                query: this.latestLeafQuery,
            }).data;
            if (latest.length == 1) return latest[0];

            return undefined;
        },
    },

    methods: {
        ...mapActions('leaves', {
            getLeaf: 'get',
            findLeaves: 'find',
        }),
    },

    async created() {
        await this.findLeaves({ query: this.latestLeafQuery });
    },

    watch: {
        latestLeaf() {
            this.latestLeafShow = true;
        },
    },
};
</script>
