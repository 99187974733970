import yaml from 'js-yaml';

import Vue from 'vue';
import Vuex from 'vuex';
import feathersVuex from 'feathers-vuex';

import vuexLocal from '@/plugins/vuex-persist';

import feathersClient from './feathers-client';

const STATUS_NOT_BUILT = 'not built';
const STATUS_VALIDATING = 'waiting';
const STATUS_VALIDATION_SUCCEEDED = 'validated';
const STATUS_VALIDATION_FAILED = 'failed';

const { service, FeathersVuex } = feathersVuex(feathersClient, {
    idField: '_id',
    enableEvents: true,
});

Vue.use(Vuex);
Vue.use(FeathersVuex);

Vue.config.devtools = true;

// FIXME: Nuxt.js's Vuex "classic mode" (deprecated)
export default function createStore() {
    return new Vuex.Store({
        strict: process.env.NODE_ENV !== 'production',

        state: {
            sessions: {
                last: null,
            },
        },
        mutations: {
            saveSession(state, { chainId, leafId }) {
                this.commit('leaves/setCurrent', leafId);
                state.sessions[chainId] = leafId;
            },
            setLastChain(state, chainId) {
                state.sessions['last'] = chainId;
            },
        },

        plugins: [
            vuexLocal.plugin,

            service('blocks'),
            service('chains', {
                state: {
                    idField: 'publicKey',
                },
            }),
            service('counters', { autoRemove: true }),
            service('leaves', {
                state: {
                    setCurrentOnCreate: false,
                },
                // eslint-disable-next-line no-unused-vars
                instanceDefaults(data, { store, Model, Models }) {
                    return {
                        isComplete: false,

                        get public() {
                            if (this.preimage) {
                                return yaml.safeDump(JSON.parse(this.preimage));
                            }

                            return undefined;
                        },

                        get color() {
                            if (this.status == STATUS_VALIDATING)
                                return 'yellow';
                            else if (this.status == STATUS_VALIDATION_SUCCEEDED)
                                return 'green';
                            else if (this.status == STATUS_VALIDATION_FAILED)
                                return 'orange';

                            return undefined;
                        },
                        get icon() {
                            if (this.status == STATUS_NOT_BUILT)
                                return 'mdi-account-outline';
                            else if (this.status == STATUS_VALIDATING)
                                return 'mdi-account-search';
                            else if (this.status == STATUS_VALIDATION_SUCCEEDED)
                                return 'mdi-account-check';
                            else if (this.status == STATUS_VALIDATION_FAILED)
                                return 'mdi-account-alert';
                            else return 'mdi-account';
                        },
                        get outlined() {
                            if (this.status == STATUS_NOT_BUILT) return true;
                            return false;
                        },
                        get status() {
                            if (!this.block) {
                                return STATUS_NOT_BUILT;
                            } else if (this.validation) {
                                if (this.validation.successful == true)
                                    return STATUS_VALIDATION_SUCCEEDED;
                                else if (this.validation.successful == false)
                                    return STATUS_VALIDATION_FAILED;
                                else return STATUS_VALIDATING;
                            }

                            return undefined;
                        },
                    };
                },
                setCurrentOnGet: false,
                whitelist: ['$exists', '$inc', '$not', '$select', '$unset'],
            }),
            service('receipts'),
            service('send-chain-receipts'),
            service('validation-requests'),
        ],
    });
}
